import React from 'react';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../components/buttons/button';
import { Route } from '../models/site-links';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "patronscan-icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO title="404: Not found" />
      <section className="min-h-screen max-w-container flex justify-center items-center mx-auto">
        <div className="h-full text-center">
          <Img fluid={data.logo.childImageSharp.fluid} />
          <h1 className="mt-8 text-5xl font-heading">NOT FOUND</h1>
          <p>The page you tried to visit doesn't exist. Sorry about that.</p>
        </div>
      </section>
    </>
  );
};

export default NotFoundPage;
