import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import './index.css';
import Img from 'gatsby-image';
import NotFoundPage from './404';

declare const window: any;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "patronscan-icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div>
      <SEO
        title="Patronscan ID Scanners | Trusted ID Verification"
        description="Leading ID scanning technology. Proven Fake ID detection, fraud protection and age verification. Over 280,000 fake IDs caught. Data captures and reporting."
      />
      <NotFoundPage />
    </div>
  );
};

export default IndexPage;
